<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />

        <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">
            <template v-for="(item, key) in list_check_box"> 
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                    <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->

                    <!-- Section Detail Start -->
                    
                    <!--indication start-->
                    <Indication v-if="key=='detail_indication' && item.show"/>          
                    <!--indication end-->

                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show"/>
                    <!-- treatment_line end -->

                     <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>

                    <!--Conclusion start-->             
                    <Conclusion v-else-if="key == 'detail_Conclusion' && item.show" />  
                    <!--Conclusion end-->

                    <!--restriction start-->         
                    <div class="content-onglets table"  v-else-if="key == 'detail_restriction' && item.show">                             
                        <p v-html="check_empty(data.pmda['restriction'])" ></p>                        
                    </div> 
                    <!--restriction end-->                   
                     
                    <EssaisClinique v-else-if="key == 'detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>     

                    <!--comment start--> 
                    <div class="content-onglets table" v-else-if="key == 'detail_comment' && item.show">                           
                        <p v-html="check_empty(data.pmda['commnet'])" ></p>                        
                    </div> 
                    <!--comment end-->
 
                    <!-- @todo detail_EssaisClinique       -->

                    <!-- 'sub_detail/EconomicEvaluation.ctp' -->
                    <EconomicEvaluation v-else-if="key == 'detail_EconomicEvaluation' && item.show" :data="data.data_links['EconomicEvaluation']" />
                    <!-- Section Detail End -->

                </div>
            </template>

            <!-- Link Section Start-->       
           <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->
			

        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LeftSection from '../LeftSection.vue'
import TreatmentLine from '../treatment_line.vue'
import EconomicEvaluation from '../economic_evaluation.vue'
import Conclusion from '../conclusion.vue'
import LinkAgency from '../LinkAgency.vue'
import EssaisClinique from '../../elements/essais_clinique_detail.vue'
import KeyDocument from '../key_document.vue'
import Indication from '../Indication.vue'
import { check_empty, check_detail_conclusion, key_documents } from '../../../utils'

export default {
    name: 'pmda',
    components : {
        LeftSection,
        TreatmentLine,
        EconomicEvaluation,       
        Conclusion,
        LinkAgency,
        EssaisClinique,
        KeyDocument,
        Indication
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_indication : {'title' : 'Indication', 'show' : true, 'enable' : false, 'orange_text':false},
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_Conclusion : {'title' : 'Rationale and Commentary', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_restriction : {'title' : 'Restriction', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_clinical_trials : {'title' : 'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_comment : {'title' : 'comment_detail', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_EssaisClinique : {'title' : 'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_EconomicEvaluation : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false},
            }
        }
    },
    created(){
        // condition to show check box in left section [enable]
        if (this.data && this.data.pmda){
            
            if (this.data['indication_en']){
                this.list_check_box['detail_indication'].enable = true
            }
            if(this.data.treatment_line || this.data.specificity || this.data.administration)  { 
                this.list_check_box['treatment_line'].enable = true
            }
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0){ 
                this.list_check_box['detail_key_documents'].enable = true
            }
            if(this.check_detail_conclusion(this.data, this.$i18n.locale) ||
                this.data.pmda['rationale_commentary']
                ) { 
                    this.list_check_box['detail_Conclusion'].enable = true
            }
            if (this.data.pmda['restriction']){
                this.list_check_box['detail_restriction'].enable = true
            }
            if(this.data.essais_clinique && this.data.essais_clinique.length > 0) { 
                this.list_check_box['detail_clinical_trials'].enable = true
            }
            if (this.data.pmda['commnet']){
                this.list_check_box['detail_comment'].enable = true
            }
            
            // this.list_check_box['detail_EssaisClinique'].enable = true
                            
            if(this.data.data_links && this.data.data_links['EconomicEvaluation'] && this.data.data_links['EconomicEvaluation'].length > 0) { 
                this.list_check_box['detail_EconomicEvaluation'].enable = true                    
            }      
        }
    },
    methods : {
        check_empty,
        check_detail_conclusion,
        key_documents
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }        
			
    },
}
</script>

<style>

</style>